<template>
  <el-scrollbar :native="false" class="service-data-scrollbar" v-if="moduleId!=35">
    <div class="service-data-table ">
      <div class="data-table-header">
        <div class="table-header-item" style="flex: 0.9;text-align: left"><span style="margin-left: 40px">机器人名称</span></div>
        <div class="table-header-item" style="flex: 0.6"><span>问题类型</span></div>
        <div class="table-header-item" style="margin-right: 10px"><span>客户问题</span></div>
        <div class="table-header-item" style="margin-right: 10px"><span>解析</span></div>
        <div class="table-header-item" style="margin-right: 10px"><span>不得分关键词</span></div>
        <div class="table-header-item" style="margin-right: 10px"><span>得分操作</span></div>
        <div class="table-header-item" style="margin-right: 10px"><span>得分详情</span></div>
        <div class="table-header-item" style="flex: 0.6"><span>得分（{{score}}分）</span></div>
      </div>
      <div class="data-table-body" v-if="robotList.length">
        <div class="table-body-item" v-for="(item,index) in robotList">
          <div class="body-item-top">
            <div class="item-top-item" style="flex: 0.9;justify-content: flex-start;">
              <i style="margin-left: 40px" @click="item.isShow = !item.isShow" v-show="item.isShow" class="iconfont">&#xe65f;</i>
              <i style="margin-left: 40px" @click="item.isShow = !item.isShow" v-show="!item.isShow" class="iconfont">&#xe65d;</i>
              <span class="text-overflow">{{item.robot_name}}</span>
            </div>
            <div class="item-top-item" style="flex: 0.6"></div>
            <div class="item-top-item" style="margin-right: 10px"></div>
            <div class="item-top-item" style="margin-right: 10px"></div>
            <div class="item-top-item" style="margin-right: 10px"></div>
            <div class="item-top-item" style="margin-right: 10px"></div>
            <div class="item-top-item" style="margin-right: 10px"></div>
            <div class="item-top-item" style="flex: 0.6"><span class="text-overflow">{{item.score}}</span></div>
          </div>
          <div class="body-item-bottom" v-for="issueItem in item.question" v-show="item.isShow">
            <div class="item-bottom-item" style="flex: 0.9"></div>
            <div class="item-bottom-item" style="flex: 0.6"><span>{{issueItem.type}}</span></div>
            <div class="item-bottom-item" style="margin-right: 10px"><el-input v-model="issueItem.question" :readonly="true" size="small"></el-input></div>
            <div class="item-bottom-item" style="margin-right: 10px"><el-input :readonly="true" v-model="issueItem.analysis" size="small"></el-input></div>
            <div class="item-bottom-item" style="margin-right: 10px" v-if="issueItem.no_score_keyword"><el-input :readonly="true" v-model="issueItem.no_score_keyword" size="small"></el-input></div>
            <div class="item-bottom-item" style="margin-right: 10px" v-else></div>
            <div class="item-bottom-item" style="margin-right: 10px" v-if="issueItem.no_score_keyword"><el-input :readonly="true" v-model="issueItem.score_operate" size="small"></el-input></div>
            <div class="item-bottom-item" style="margin-right: 10px;justify-content: flex-start" v-else><span style="margin-left: 4px">{{issueItem.score_operate}}</span></div>
            <div class="item-bottom-item" style="margin-right: 10px" v-if="issueItem.no_score_keyword"><el-input :readonly="true" v-model="issueItem.score_detail" size="small"></el-input></div>
            <div class="item-bottom-item" style="margin-right: 10px" v-else><el-link @click="toDetail(issueItem.score_detail)" :underline="false" type="primary">查看详情</el-link></div>
            <div class="item-bottom-item" style="flex: 0.6"><span class="text-overflow">{{issueItem.score}}</span></div>
          </div>
        </div>
      </div>
      <div class="no-data" v-else style="text-align: center;border: 1px solid #E5E5E5;line-height: 70px">暂无数据</div>
    </div>
  </el-scrollbar>
  <div v-else>
    <template >
                <el-table :data="groupList" class="customTable goods-text-table" style="width: 100%; flex: 1; margin-top: 20px">
                    <el-table-column prop="content" label="考试内容"></el-table-column>
                    <el-table-column label="设置填写问题" prop="question" align="left"></el-table-column>
                    <el-table-column label="学生提交答案" prop="student" align="left">
                        <template slot-scope="scope">
                            <span style="color: #1cb064;cursor: pointer;" v-if="scope.row.content =='image'" @click="preview(scope.row.student)">查看详情</span>
                            <span v-else>{{scope.row.student}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="正确答案" prop="normal" align="left"></el-table-column>
                    <el-table-column label="得分" prop="score" align="left">
                        <template slot="header" slot-scope="scope">
                            <span>得分（{{score}}）</span>
                        </template>
                    </el-table-column>
                </el-table>
            </template>

  </div>
</template>

<script>
import {examStuKefuExamDetail, stuOpTrainData,networkMarketingDetail,} from '@/utils/apis'
export default {
  name: "StoreCustomer",
  data(){
    return {
      stuId:this.$route.query.id || null,
      examId:this.$route.query.examId || null,
      trainId:this.$route.query.trainId || null,
      robotList:[],
      score:'',
      moduleId:Number(this.$route.query.moduleId) || null,
      role:Number(localStorage.getItem('role')) || null,
      groupList:[], // 社群推广,
      img:''
    }
  },
  mounted() {
    this.getExamStuKefuExamDetail()
    // console.log(this.moduleId);
    this.getShequnData()
  },
  methods:{
    getExamStuKefuExamDetail(){
      let params = {
        stu_id:this.stuId,
        exam_id:this.examId
      }
      examStuKefuExamDetail(params).then((res)=>{
        if(res.data.robot_data.length){
          res.data.robot_data.forEach(item=>{
            this.$set(item, 'isShow', true)
          })
        }
        this.robotList = res.data.robot_data;
        this.score = res.data.score;
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    toDetail(obj){
      localStorage.setItem('issueItem', JSON.stringify(obj))
      if(this.moduleId === 21){
        if(this.role === 3){
          this.$router.push({
            path:'/trainingCenter/practicalTraining/answerDetailServiceDetail',
            query:{
              id:this.stuId,
              examId:this.examId
            }
          })
        } else {
          this.$router.push({
            path:'/student/trainCenter/answerDetailServiceDetail',
            query:{
              id:this.stuId,
              examId:this.examId
            }
          })
        }

      } else {
        if(this.$route.meta.tabName === 'examManageExamDataStoreCustomer'){
          this.$router.push({
            path:'/examManage/examData/storeCustomerDetail',
            query:{
              id:this.stuId,
              examId:this.examId
            }
          })
        } else {
          this.$router.push({
            path:'/judgesManage/judgesData/storeCustomerDetail',
            query:{
              id:this.stuId,
              examId:this.examId
            }
          })
        }
      }
    },
    toBack(){
      this.$router.go(-1)
    },
    getShequnData(){
      if (this.moduleId ===35) {

                    // if (this.$route.query.method === 'stuTrain' || this.$route.query.method === 'teaTrain') { // 学生训练、教师训练
                    //     // params.train_id = this.$route.query.trainId
                    //       // params.student_id = this.$route.query.id; // 学生id
                    //       examStuKefuExamDetail(params).then((res) => {
                    //         this.groupList = res.data.list
                    //              this.score = res.data.score

                    //     })
                    // } else
                    if (this.$route.query.method === 'teaExam' || this.$route.query.method === 'teaExamTrain') { // 教师——考试中心——考试数据、实训数据
                      let params = {
        stu_id:this.stuId,
        exam_id:this.examId
      }
                        examStuKefuExamDetail(params).then((res) => {
                           this.groupList = res.data.list
                                 this.score = res.data.score
                        })
                    }
                }
    },
    preview(val){
                this.videoState = true
                this.img = val
            },
  }
}
</script>

<style scoped lang="scss">
.service-data-scrollbar{
  height: 100%;
  ::v-deep .el-scrollbar__wrap{
    overflow-x: hidden;
    .el-scrollbar__view{
      padding: 20px;
    }
  }
}
.service-data-table{
  margin-top: 20px;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  .data-table-header{
    display: flex;
    background: #F6F6F6;
    height: 60px;
    line-height: 60px;
    box-sizing: border-box;
    text-align: center;
    span{
      color: #666666;
      font-size: 16px;
    }
    .table-header-item{
      flex: 1;
      width: 1%;
      box-sizing: border-box;
    }
  }
  .data-table-body{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .table-body-item{
      display: flex;
      flex-direction: column;
      .body-item-top, .body-item-bottom{
        box-sizing: border-box;
        width: 100%;
        display: flex;
        border-bottom: 1px solid #EEEEEE;
        .item-top-item, .item-bottom-item{
          width: 1%;
          flex: 1;
          height: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
          span{
            //width: 100%;
            display: inline-block;
          }
          i{
            line-height: 1;
            cursor: pointer;
            font-size: 18px;
            color: #7D7D7D;
            margin-right: 4px;
          }
        }
      }
    }
  }
}
</style>
